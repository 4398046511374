import moment from "moment";
import { getTeams } from "@/services/index";
import DialogSendReportVue from "../DialogSendReport/DialogSendReport.vue";

export default {
  name: "Header",

  components: {
    DialogSendReportVue,
  },

  data: () => ({
    modalReport: false,
    greeting: "Bem vindo,",
    userHeader: {},
    dates: moment().format("YYYY-MM"),
    isLoading: false,
    menu: false,
    modal: false,
    tabs: [],
    campaigns_business: [],
    start_datetime: null,
    end_datetime: null,
  }),

  methods: {
    changeTab(value) {
      this.tabId = value.team_id;
      this.getCampaign();
    },

    async getCampaign() {
      const newDate = moment(this.dates);
      this.start_datetime = moment(newDate).startOf("month").unix();
      this.end_datetime = moment(newDate).endOf("month").unix();

      const payload = {
        filter: null,
        startDatetime: this.start_datetime,
        endDatetime: this.end_datetime,
        offset: 0,
        limit: 25,
        orderby: "campaign_id",
        direction: "desc",
      };

      if (this.tabs.length > 0) {
        payload.teamsFilter2 = `{'team_id':${this.tabId}}`;
        payload.teamId = this.tabId;
        this.$store.dispatch("getCountCampaign", payload);
        this.$store.dispatch("getCampaign", payload);
      }
    },

    handlerModal(){
      this.modalReport = true
    }
  },

  computed: {
    dateRange() {
      this.getCampaign();
      if (this.dates.length == 0 || this.dates == []) {
        this.dates = moment().format("YYYY-MM");
      }
      return this.dates;
    },
    inputSearch: {
      get() {},
      set(value) {
        this.$store.commit("UPDATE_INPUT_SEARCH", value);
      },
    },
  },

  async created() {
    this.userHeader = this.$store.state.user;

    const variablesTeams = {
      filter: `{'team_business_active': true}`,
    };

    const responseTeams = await getTeams(variablesTeams);
    this.tabs = responseTeams;
    this.tabId = responseTeams[0].team_id;
  },
};
